import React from "react";
import { primaryColorCodes, convertPrimaryColorToHex } from "./utilities/listOfPrimaryColors"
import getCorrectTextColor from "./utilities/getCorrectTextColor";

export default function PrimaryColors(props) {
 
    const displayOrder = ["red", "orange", "brown", "yellow", "green", "turquoise", "blue", "purple", "pink", "grey", "black", "white"]

    function getHex(color){
        const colorDetails = props.colors[color].filter((colorObject => Object.keys(colorObject)[0] === color))
        return colorDetails[0][color]
    }

    console.log(getHex("red"))

    function colorCellClasses(color) {
        return props.selectedPrimaryColor === color
            ? "color-cell selected"
            : "color-cell";
    }

    function doneIconClasses(color) {
        return props.selectedPrimaryColor === color
            ? "done-icon highlighted"
            : "done-icon";
    }

    function getLabelColor(color) {
        if (props.selectedPrimaryColor === color) {
            return "#000000";
        } 
        else {
            return color === "white"
                ? "#000000"
                : `#${getCorrectTextColor(getHex(color))}`;
        }
    }
    const PrimaryColorsClasses = props.selectedPrimaryColorIsVisible
        ? "primary-colors-container reduced"
        : "primary-colors-container"; 

    return (
        <div className={PrimaryColorsClasses} >
            {displayOrder.map((colorName, index) => (
                <div
                    style={{ backgroundColor: colorName }}
                    onClick={(e) =>
                        props.handlePrimaryColorClick(e, colorName)
                    }
                    className={colorCellClasses(colorName)}
                    key={`primary-color${index}`}
                >
                    <div
                        className="primary-colors-color-name"
                        style={{
                            color: getLabelColor(colorName),
                        }}
                    >
                        {String(colorName)}
                    </div>
                    <img
                        className={doneIconClasses(colorName)}
                        src="/img/done-24px.svg"
                        alt="done-icon"
                    />
                </div>)
            )}
        </div>
    );
}