import React, { useState, useEffect } from "react";
import PrimaryColors from "./PrimaryColors";
import Header from "./Header"
import SelectedPrimaryColorOptionsList from "./SelectedPrimaryColorOptionsList"
import { CSSTransition } from 'react-transition-group';
import smoothscroll from "smoothscroll-polyfill";

export default function ColorApp(props) {
  const [selectedPrimaryColor, setselectedPrimaryColor] = useState();
  const [
    selectedPrimaryColorIsVisible, setselectedPrimaryColorIsVisible] = useState(false);

  function handlePrimaryColorClick(e, color) {
    e.preventDefault;
    setselectedPrimaryColorIsVisible(true)
    setselectedPrimaryColor(color)
  }

  // cross browser smooth scrolling library to fix scrolling not working on mobile
  smoothscroll.polyfill();

  return (
    <CSSTransition appear={true} in={true} timeout={200} classNames="fade-in">
      <div className="main-container">
        <Header/>
        <div className="content-container">
          <PrimaryColors selectedPrimaryColor={selectedPrimaryColor} colors={props.data.colors} handlePrimaryColorClick={handlePrimaryColorClick} selectedPrimaryColorIsVisible={selectedPrimaryColorIsVisible}/>
          {selectedPrimaryColorIsVisible && 
            <SelectedPrimaryColorOptionsList selectedPrimaryColor={selectedPrimaryColor} colors={props.data.colors} />}
        </div>
      </div>
    </CSSTransition>
  );
}
