import React, {useState, useEffect} from "react";
import ClipboardConfirmation from "./ClipboardConfirmation";
import { CSSTransition } from 'react-transition-group';

export default function SelectedHTMLColorDetails(props) {
    const [clipboardIsOpen, setClipBoardIsOpen] = useState(false)
    const [clipboardValue, setClipboardValue] = useState(null)
  
    const colorName = props.selectedHTMLColor && Object.keys(props.selectedHTMLColor)[0]
    const colorCode = props.selectedHTMLColor && props.selectedHTMLColor[colorName]

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
            
    function closeClipboardConfirmation() {
        setClipBoardIsOpen(false)
    }; 

    function handleDetailClick(e) {
      var span = e.currentTarget.querySelector('span');
      const value = span.innerText
      setClipboardValue(value);
      copyValueToClipboard(value);
      setClipBoardIsOpen(true)
    }

    function copyValueToClipboard(value) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = value;
      dummy.select();
      setClipboardValue(dummy.value);
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }

    // automatically closes confirmation component
    useEffect(() => {
      clipboardIsOpen === true &&
        setTimeout(() => setClipBoardIsOpen(false), 2000);
    }, [clipboardIsOpen]);

    const selectedColorRGB = hexToRgb(colorCode)

    return (
      <CSSTransition in={props.showSelectedHTMLColor} mountOnEnter={true} unmountOnExit={true} timeout={200} classNames="slide-up">
      <div
        className="selected-color-details-container"
        style={{ backgroundColor: colorCode }}
      >
        <div className="selected-color-details-wrapper">
            {props.selectedHTMLColor &&
            <>
              <div className="selected-color-details-item name-details">
                <strong>Name</strong>
                <span onClick={(e) => handleDetailClick(e)}>
                  <span>{colorName}</span> 
                  <i className="fas fa-copy"></i>
                </span>
              </div>
              <div className="selected-color-details-item hex-details">
                <strong>HEX</strong>
                <span onClick={(e) => handleDetailClick(e)}>
                  
                  <span>{colorCode}</span>
                  <i className="fas fa-copy"></i>
                </span>
              </div>
              <div className="selected-color-details-item rgb-details">
                <strong>RGB</strong>
                <span onClick={(e) => handleDetailClick(e)}>
                  <span>{`${selectedColorRGB.r}, ${selectedColorRGB.g}, ${selectedColorRGB.b}`}</span>
                  <i className="fas fa-copy"></i>
                </span>
              </div>
            </>
            }
          
        </div>
        {clipboardIsOpen && (
          <ClipboardConfirmation
            closeClipboardConfirmation={closeClipboardConfirmation}
            clipboardValue={clipboardValue}
          />
        )}
      </div>
      </CSSTransition>
    );
}