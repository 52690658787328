import React from "react";
import { CSSTransition } from "react-transition-group";

export default function ClipboardConfirmation(props) {
    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="fade-in">
            <div
                className="clipboard-confirmation-container"
                onClick={props.closeClipboardConfirmation}
            >
                <span>
                    "{props.clipboardValue}" is now copied to your clipboard!
        </span>
                <i className="fas fa-times"></i>
            </div>
        </CSSTransition>
    );
}
