import React, { useState, useEffect, useRef } from "react";
import SelectedHTMLColorDetails from "./SelectedHTMLColorDetails"
import PreviousNextButtons from "./PreviousNextButtons"
import getCorrectTextColor from "./utilities/getCorrectTextColor";

import { CSSTransition } from 'react-transition-group';

export default function selectedPrimaryColorOptionsList(props) {
    const [selectedHTMLColor, setSelectedHTMLColor ] = useState(null);
    const [showSelectedHTMLColor, setShowSelectedHTMLColor] = useState(false)
    const [HTMLColorPageToBeRendered, setHTMLColorPageToBeRendered] = useState(1);
    const [showNextPageButton, setShowNextPageButton] = useState(true);
    
    const containerRef = useRef();

    const HTMLColorRef = useRef(selectedHTMLColor);
    HTMLColorRef.current = selectedHTMLColor;

    const selectedPrimaryColorOptionsList = props.colors[props.selectedPrimaryColor]

    function handleHTMLColorClick(e, color){
        e.preventDefault;
        setSelectedHTMLColor(color)
        setShowSelectedHTMLColor(true)
    }

    function getHTMLColorListSliceToBeRendered(HTMLColorPageToBeRendered) {
        const endIndex = HTMLColorPageToBeRendered * 8;
        const startIndex = endIndex - 8;
        if (selectedPrimaryColorOptionsList.length > 8) {
            return Array.from(selectedPrimaryColorOptionsList).slice(startIndex, endIndex);
        } else {
            return Array.from(selectedPrimaryColorOptionsList);
        }
    }
    
    function goToNextPalettePage() {
        setHTMLColorPageToBeRendered(HTMLColorPageToBeRendered + 1);
    }

    function goToPreviousPalettePage() {
        setHTMLColorPageToBeRendered(HTMLColorPageToBeRendered - 1);
    }
     // scrolls to HTML colors div - applies to mobile layout only
    useEffect(() => {
        window.scrollTo({
          top: containerRef.current.offsetTop,
          behavior: "smooth",
        });
    }, [selectedPrimaryColorOptionsList]);

    // reinitializes state when palette selection changes and triggers render of default color details component
    useEffect(() => {
      setSelectedHTMLColor(null);
      setHTMLColorPageToBeRendered(1);
      setShowSelectedHTMLColor(false);
      setTimeout(intialize, 1500)
    }, [selectedPrimaryColorOptionsList]);

    function intialize() {
      if (HTMLColorRef.current === null){
        setShowSelectedHTMLColor(true);
        setSelectedHTMLColor(selectedPrimaryColorOptionsList[0]);
      }   
    }

    // handles 'next' button render
    useEffect(() => {
        if (
            selectedPrimaryColorOptionsList.length < 9 ||
            getHTMLColorListSliceToBeRendered(HTMLColorPageToBeRendered + 1)
                .length === 0
        ) {
            setShowNextPageButton(false);
        } else {
            setShowNextPageButton(true);
        }
    }, [getHTMLColorListSliceToBeRendered(HTMLColorPageToBeRendered)]);

    function colorCellClasses(color) {
      return selectedHTMLColor === color
        ? "selected-color-cell selected"
        : "selected-color-cell";
    }

    function getLabelStyle(color) {
        if (selectedHTMLColor === color) {
            const selectedStyle = {
                backgroundColor: "white",
                color: "black",
                opacity: 1
            }
            return selectedStyle;
        }
        else {
            const unSelectedStyle = {
              color: `#${getCorrectTextColor(color[getColorName(color)])
              }`,
            };
            return unSelectedStyle
        }
    }
    function getColorName(colorObject){
     return Object.keys(colorObject)[0];

    }
    return (
      <CSSTransition
        appear={true}
        in={true}
        timeout={200}
        classNames="side-slide"
      >
        <div ref={containerRef} className="selected-color-container">
          <div className="selected-color-wrapper">
            {getHTMLColorListSliceToBeRendered(HTMLColorPageToBeRendered).map(
              (color, index) => (
                <div
                  className={colorCellClasses(color)}
                  onClick={(e) => handleHTMLColorClick(e, color)}
                  key={`html-color-${index}`}
                  style={{ backgroundColor: getColorName(color)}}
                >
                  <span style={getLabelStyle(color)}>{getColorName(color)}</span>
                </div>
              )
            )}
          </div>
          <PreviousNextButtons
            HTMLColorPageToBeRendered={HTMLColorPageToBeRendered}
            goToNextPalettePage={goToNextPalettePage}
            goToPreviousPalettePage={goToPreviousPalettePage}
            showNextPageButton={showNextPageButton}
          />

          <SelectedHTMLColorDetails
            selectedHTMLColor={selectedHTMLColor}
            showSelectedHTMLColor={showSelectedHTMLColor}
          />
        </div>
      </CSSTransition>
    );
} 