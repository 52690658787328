import React from "react";
import { CSSTransition } from "react-transition-group";

export default function PreviousNextButtons(props) {
    return (
      <div className="previous-next-buttons">
        {props.HTMLColorPageToBeRendered > 1 && (
          <CSSTransition
            appear={true}
            in={true}
            timeout={200}
            classNames="fade-in"
          >
            <a
              className="previous-link"
              onClick={props.goToPreviousPalettePage}
            >
              <i className="fas fa-chevron-left"></i>
              <span>previous colors</span>
              <strong>|</strong>
            </a>
          </CSSTransition>
        )}
        {props.showNextPageButton && (
          <CSSTransition
            appear={true}
            in={true}
            timeout={200}
            classNames="fade-in"
          >
            <a className="next-link" onClick={props.goToNextPalettePage}>
              <span>next colors</span>
              <i className="fas fa-chevron-right"></i>
            </a>
          </CSSTransition>
        )}
      </div>
    );
}