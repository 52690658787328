import React from "react";
import { CSSTransition } from "react-transition-group";
import SocialIcons from "./SocialIcons";

export default function About(props) {
  return (
    <div className="about-container">
      <CSSTransition in={true} appear={true} timeout={200} classNames="fade-in">
        <div className="about-text">
          <i
            class="fas fa-times close-icon"
            onClick={props.closeAboutModal}
          ></i>
          <h1>
            <img
              className="about__logo"
              src="/img/palette-logo-hi.png"
              alt="palette-logo"
            />{" "}
            <br />
            was built by:
          </h1>

          <div className="name-avatar">
            <img className="overdub-pic" src="/img/overdub.png" alt="overdub" />
            <h2>overDub</h2>
          </div>

          <p>
            <a href="http://www.overdub.dev/" target="_blank">
              overDub
            </a>{" "}
            is a front-end developer, a music producer and a globe-trotter born
            in France and currently based in Lisbon, Portugal. His favourite
            tools are Javascript, React and Ableton Live. In his free time he is
            also the man behind the music producer iZem and the the label{" "}
            <a href="http://www.elis-records.com/" target="_blank">
              Elis Records
            </a>{" "}
            .
          </p>
          <SocialIcons
            websiteLink={"https://www.overdub.dev/"}
            linkedinLink={
              "https://www.linkedin.com/in/jeremiemoussaidkerouanton/"
            }
            emailAddress={"jeremieemk@gmail.com"}
            twitterLink={"https://twitter.com/overDub8"}
            instaLink={"https://www.instagram.com/izem__music/"}
          />
          <div className="name-avatar">
            <img
              className="overdub-pic"
              src="/img/mattdelac.png"
              alt="matt-delac"
            />
            <h2>Matt Delac</h2>
          </div>

          <p>
            Originally from the south of France, Matt is the Founder of{" "}
            <a href="https://www.uplabs.com/" target="_blank">
              UpLabs
            </a>{" "}
            (backed by Y Combinator) and{" "}
            <a href="https://www.shecodes.io/" target="_blank">
              SheCodes Workshops
            </a>
            . He also worked with Google, Airbnb and Envato as an engineer.
            Primarily focusing on Ruby, Rails and React, he is the developer
            behind{" "}
            <a href="https://materialpalette.com/" target="_blank">
              Material Palette
            </a>{" "}
            and{" "}
            <a href="http://delac.io/wow" target="_blank">
              WOW.js
            </a>
            . Matt has lived in 12 countries, 4 continents, 15 cities and
            countless shared houses.
          </p>
          <SocialIcons
            websiteLink={"https://www.delac.io/"}
            linkedinLink={"https://www.linkedin.com/in/mattdelac/"}
            emailAddress={"matt@delac.io"}
            twitterLink={"https://twitter.com/matcodes"}
            instaLink={"https://www.instagram.com/matthieu/"}
          />
          {/* <div className="name-avatar">
            <img classoverDub8="overdub-pic" src="/img/vitalyi.png" alt="vitalyi" />
            <h2>Vitalyi</h2>
          </div>

          <p>
            overDub is a front-end developer, a music producer and a
            globe-trotter born in France and currently based in Lisbon,
            Portugal.
          </p>
          <div className="about-icons">
            <i class="fab fa-github"></i>
            <i class="fab fa-linkedin"></i>
            <i class="far fa-envelope"></i>
            <i class="fab fa-twitter"></i>
          </div> */}
        </div>
      </CSSTransition>
    </div>
  );
}
